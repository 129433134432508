import React from "react";
import PropTypes from "prop-types";
import { intlShape, injectIntl } from "react-intl";
import bindAll from "lodash.bindall";
import { connect } from "react-redux";
import axios from "axios";

import { setProjectUnchanged } from "../reducers/project-changed";
import { setPlayer, setFullScreen } from "../reducers/mode";
import {
    LoadingStates,
    getIsCreatingNew,
    getIsFetchingWithId,
    getIsLoading,
    getIsShowingProject,
    onFetchedProjectData,
    projectError,
    setProjectId,
} from "../reducers/project-state";
import { activateTab, BLOCKS_TAB_INDEX } from "../reducers/editor-tab";

import log from "./log";
import storage from "./storage";

/* Higher Order Component to provide behavior for loading projects by id. If
 * there's no id, the default project is loaded.
 * @param {React.Component} WrappedComponent component to receive projectData prop
 * @returns {React.Component} component with project loading behavior
 */
const ProjectFetcherHOC = function (WrappedComponent) {
    class ProjectFetcherComponent extends React.Component {
        constructor(props) {
            super(props);
            bindAll(this, ["fetchProject"]);
            storage.setProjectHost(props.projectHost);
            storage.setAssetHost(props.assetHost);
            storage.setTranslatorFunction(props.intl.formatMessage);
            // props.projectId might be unset, in which case we use our default;
            // or it may be set by an even higher HOC, and passed to us.
            // Either way, we now know what the initial projectId should be, so
            // set it in the redux store.
            if (
                props.projectId !== "" &&
                props.projectId !== null &&
                typeof props.projectId !== "undefined"
            ) {
                this.props.setProjectId(props.projectId.toString());
            }
        }

        decrypt = (salt, encoded) => {
            console.log("encoded user id", encoded);
            const textToChars = (text) =>
                text.split("").map((c) => c.charCodeAt(0));
            const applySaltToChar = (code) =>
                textToChars(salt).reduce((a, b) => a ^ b, code);
            return encoded
                .match(/.{1,2}/g)
                .map((hex) => parseInt(hex, 16))
                .map(applySaltToChar)
                .map((charCode) => String.fromCharCode(charCode))
                .join("");
        };

        componentDidUpdate(prevProps) {
            console.log("test1", document.getElementById("teacherSaveNow"));
            if (prevProps.projectHost !== this.props.projectHost) {
                storage.setProjectHost(this.props.projectHost);
            }
            if (prevProps.assetHost !== this.props.assetHost) {
                storage.setAssetHost(this.props.assetHost);
            }
            if (this.props.isFetchingWithId && !prevProps.isFetchingWithId) {
                this.fetchProject(
                    this.props.reduxProjectId,
                    this.props.loadingState
                );
            }
            if (this.props.isShowingProject && !prevProps.isShowingProject) {
                this.props.onProjectUnchanged();
            }
            if (
                this.props.isShowingProject &&
                (prevProps.isLoadingProject || prevProps.isCreatingNew)
            ) {
                this.props.onActivateTab(BLOCKS_TAB_INDEX);
            }
        }
        componentDidMount() {
            console.log("test", document.getElementById("teacherSaveNow"));
        }

        fetchProject(projectId, loadingState) {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let user_id = params.get("user_id");
            let share = params.get("share");
            let homework_id = params.get("homework_id");
            let env = params.get("env");
            let teacher_copy = params.get("teacher_copy");
            let student_copy = params.get("student_copy");
            let share_status = false;
            if (env == "production") {
                var baseUrl =
                    "https://www.begalileo.com/access_codings/get_project";
            } else if (env == "staging") {
                var baseUrl =
                    "https://staging.begalileo.com/access_codings/get_project";
            } else if (env == "test") {
                var baseUrl =
                    "https://testserver.begalileo.com/access_codings/get_project";
            } else {
                var baseUrl =
                    "https://www.begalileo.com/access_codings/get_project";
            }

            if (share != null) {
                share_status = true;
                user_id = this.decrypt(
                    "saltsssds lagejfgjlaregjlfdgfajdglajadgljsdhgljggfdj lg",
                    user_id
                );
                console.log({ user_id });
            }
            // user_id = this.decrypt(
            //     "saltsssds lagejfgjlaregjlfdgfajdglajadgljsdhgljggfdj lg",
            //     user_id
            // );
            let project_id = params.get("project_id");
            let student_activity_id = params.get("student_activity_id");
            console.log({ user_id });
            // let student_activity_id = params.get("student_activity_id");
            if (user_id == null || project_id == null) {
                // alert("redirect it to other page");
                window.location.replace("https://www.begalileo.com/");
            } else {
                if (student_activity_id == null) {
                    if (teacher_copy != null) {
                        var url = `${baseUrl}?user_id=${user_id}&coding_learning_outcome_id=${project_id}&teacher_copy=${teacher_copy}&homework_id=${
                            homework_id || ""
                        }`;
                    } else if (student_copy != null) {
                        var url = `${baseUrl}?user_id=${user_id}&coding_learning_outcome_id=${project_id}&student_copy=${student_copy}&homework_id=${
                            homework_id || ""
                        }`;
                    } else {
                        var url = `${baseUrl}?user_id=${user_id}&coding_learning_outcome_id=${project_id}&homework_id=${
                            homework_id || ""
                        }`;
                    }
                } else {
                    url = `${baseUrl}?user_id=${user_id}&student_activity_id=${student_activity_id}&coding_learning_outcome_id=${project_id}&homework_id=${
                        homework_id || ""
                    }`;
                }
            }
            console.log("url", url);
            if (homework_id) console.log("homework_id", homework_id);
            return storage
                .load(
                    storage.AssetType.Project,
                    projectId,
                    storage.DataFormat.JSON
                )
                .then((projectAsset) => {
                    if (projectAsset) {
                        axios.get(url).then((response) => {
                            console.log({ response });
                            var mode_role = response.data.role;
                            var homework_id = response.data.homework_id;
                            console.log({ response, url });
                            if (
                                homework_id ||
                                mode_role == "content" ||
                                mode_role == "superadmin" ||
                                mode_role == "academics"
                            ) {
                                if (response.data.project_data == "") {
                                    var projectData = projectAsset.data;
                                } else {
                                    var projectData =
                                        response.data.project_data;
                                }
                            } else {
                                var projectData = response.data.project_data;
                            }

                            this.props.onFetchedProjectData(
                                projectData,
                                loadingState,
                                mode_role,
                                share_status,
                                student_activity_id
                            );
                        });
                    } else {
                        // Treat failure to load as an error
                        // Throw to be caught by catch later on
                        throw new Error("Could not find project");
                    }
                })
                .catch((err) => {
                    this.props.onError(err);
                    log.error(err);
                });
        }
        render() {
            const {
                /* eslint-disable no-unused-vars */
                assetHost,
                intl,
                isLoadingProject: isLoadingProjectProp,
                loadingState,
                onActivateTab,
                onError: onErrorProp,
                onFetchedProjectData: onFetchedProjectDataProp,
                onProjectUnchanged,
                projectHost,
                projectId,
                reduxProjectId,
                setProjectId: setProjectIdProp,
                /* eslint-enable no-unused-vars */
                isFetchingWithId: isFetchingWithIdProp,
                ...componentProps
            } = this.props;
            return (
                <WrappedComponent
                    fetchingProject={isFetchingWithIdProp}
                    {...componentProps}
                />
            );
        }
    }
    ProjectFetcherComponent.propTypes = {
        assetHost: PropTypes.string,
        canSave: PropTypes.bool,
        intl: intlShape.isRequired,
        isCreatingNew: PropTypes.bool,
        isFetchingWithId: PropTypes.bool,
        isLoadingProject: PropTypes.bool,
        isShowingProject: PropTypes.bool,
        loadingState: PropTypes.oneOf(LoadingStates),
        onActivateTab: PropTypes.func,
        onError: PropTypes.func,
        onFetchedProjectData: PropTypes.func,
        onProjectUnchanged: PropTypes.func,
        projectHost: PropTypes.string,
        projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        reduxProjectId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        setProjectId: PropTypes.func,
    };
    ProjectFetcherComponent.defaultProps = {
        assetHost: "https://assets.scratch.mit.edu",
        // assetHost: "https://scratchassets.s3.ap-south-1.amazonaws.com/",
        projectHost: "https://projects.scratch.mit.edu",
    };

    const mapStateToProps = (state) => ({
        isCreatingNew: getIsCreatingNew(
            state.scratchGui.projectState.loadingState
        ),
        isFetchingWithId: getIsFetchingWithId(
            state.scratchGui.projectState.loadingState
        ),
        isLoadingProject: getIsLoading(
            state.scratchGui.projectState.loadingState
        ),
        isShowingProject: getIsShowingProject(
            state.scratchGui.projectState.loadingState
        ),
        loadingState: state.scratchGui.projectState.loadingState,
        reduxProjectId: state.scratchGui.projectState.projectId,
    });
    const mapDispatchToProps = (dispatch) => ({
        onActivateTab: (tab) => dispatch(activateTab(tab)),
        onError: (error) => dispatch(projectError(error)),
        onFetchedProjectData: (
            projectData,
            loadingState,
            mode_role,
            share_status,
            student_activity_id
        ) => {
            // TODO: editor or view

            // alert(mode_role);
            var mode = false;

            if (mode_role == "teacher") {
                if (student_activity_id == null) {
                    // to open editor in view mode
                    // mode = true;
                    mode = false;
                    // window.removeSaveButton()

                    document.getElementById("teacherSaveNow").style.display =
                        "none";
                } else {
                    mode = false;
                    document.getElementById("teacherSaveNow").style.display =
                        "none";
                }
            } else if (
                mode_role == "content" ||
                mode_role == "superadmin" ||
                mode_role == "academics"
            ) {
                mode = false;
                console.warn({ mode_role });
            } else {
                if (share_status == true) {
                    mode = true;
                } else {
                    mode = false;
                }
            }
            if (mode) {
                dispatch(setPlayer(true));
                dispatch(setFullScreen(true));
            }
            dispatch(onFetchedProjectData(projectData, loadingState));
        },
        setProjectId: (projectId) => dispatch(setProjectId(projectId)),
        onProjectUnchanged: () => dispatch(setProjectUnchanged()),
    });
    // Allow incoming props to override redux-provided props. Used to mock in tests.
    const mergeProps = (stateProps, dispatchProps, ownProps) =>
        Object.assign({}, stateProps, dispatchProps, ownProps);
    return injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps,
            mergeProps
        )(ProjectFetcherComponent)
    );
};

export { ProjectFetcherHOC as default };
